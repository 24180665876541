<template>
    <section id="rechercheMedecin">
        <div class="title-effect position-relative">
            <h1 class="text-center title-text p-2">EFFECTUER UNE DEMANDE DE MEDECIN TRAITANT</h1>
            <div class="background-text text-center">MEDECIN TRAITANT</div>
        </div>
        <div class="container">
            <h4 class="text-center">Sur notre territoire ***** ******, beaucoup de patients n'ont toujours pas de 
                médecin référents. <br> <br>
                Pour les patients arrivant sur notre territoire ou pour ceux dont le médecin traitant cesse 
                son activité, La CPTS a mis au point un dispositif permettant au médecins généralistes
                du territoire de prendre connaissance des personnes en recherche de médecin traitant
                et de pouvoir les contacter directement lorsqu'une place se libère. <br> <br>
                Pour cela, il vous suffit de renseigner le formulaire ci-dessous, vous serez directement
                appelé par la CPTS dans les jours à venir.
            </h4>
        </div>
        <div class="container mt-5">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                          <h3>A propos du patient</h3>
                            <b-form-group label="Nom">
                                <b-form-input placeholder="Nom du patient"></b-form-input>
                            </b-form-group>  
                            <b-form-group label="Prénoms">
                                <b-form-input placeholder="Prénoms du patient"></b-form-input>
                            </b-form-group>  
                            <b-form-group label="Age">
                                <b-form-input type="number" placeholder="Age"></b-form-input>
                            </b-form-group>  
                        </div>
                        <div class="col-md-6">
                            <h3>Coordonnées</h3>
                            <b-form-group label="Adresse">
                                <b-form-input placeholder="Adresse"></b-form-input>
                            </b-form-group>  
                            <b-form-group label="Numéro téléphone">
                                <b-form-input type="phone" placeholder="Numéro téléphone"></b-form-input>
                            </b-form-group>  
                            <b-form-group label="Email">
                                <b-form-input type="email" placeholder="Email"></b-form-input>
                            </b-form-group>  
                        </div>
                        <div class="col-md-12 text-muted mt-4">
                            <b-form-checkbox
                            id="checkbox-1"
                            v-model="status"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                            >
                            En soumettant ce formulaire, j'accepte que mes informations soient utilisées exclusivement dans le cadre de ma demande
                            </b-form-checkbox>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-primary">Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name:"recherchemedecin",
    data(){
        return{
            status:""
        }
    }
}
</script>